// SEARCH && FILTER
export const STUDENT_SEARCH = "STUDENT_SEARCH";
export const STUDENT_IS_SEARCH = "STUDENT_IS_SEARCH";
export const STUDENT_SORT = "STUDENT_SORT";
export const STUDENT_FILTER = "STUDENT_FILTER";
//ERROR
export const ON_ERROR = "ON_ERROR";

// STUDENTS
export const STUDENT_LIST = "STUDENT_LIST";
export const TOP_STUDENT = "TOP_STUDENT";
export const STUDENT_VIEWID = "STUDENT_VIEWID";
export const VIEW_PROFILE = "VIEW_PROFILE"; 
//AUTHENTICATION
export const IS_AUTH = "IS_AUTH"; 
//COMMENT
export const USER_COMMENTLIST = "USER_COMMENTLIST"; 
export const USER_SUBCOMMENTS = "USER_SUBCOMMENTS";


